
import 'normalize.css/normalize.css';
import 'swiper/dist/css/swiper.css';
import './styles/index.scss';
import Swiper from 'swiper';
import Imgix from 'imgix.js';

const swiper = new Swiper('.swiper-container', {
  slidesPerView: 4,
  spaceBetween: 0,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  scrollbar: {
    el: '.swiper-scrollbar',
  },
  breakpoints: {
    768: {
      slidesPerView: 1,
    },
    1024: {
      slidesPerView: 2
    },
    1440: {
      slidesPerView: 3,
    }
  }
});